label > span:last-child {
    display: block;
    width: 100%;
}
:local(.radioButtonLabelContainer) {
    display: flex;
    :local(.radioButtonLabelText) {
        flex: 1;
        line-height: 20px;
        :local(.subTitle) {
            display: block;
            font-size: 0.9em;
        }
    }

    :local(.radioButtonLabelImage) {
        width: 80px;
    }

    // Hide required symbol from dibk-design component
    & + img {
        display: none;
    }
}
