:local(.block) {
    display: block;
}

:local(.textCenter) {
    text-align: center;
}

:local(.noMarginTop) {
    margin-top: 0;
}

:local(.noMarginBottom) {
    margin-bottom: 0;
}

:local(.marginTopSmall) {
    margin-top: 25px;
}

:local(.marginTop) {
    margin-top: 50px;
}

:local(.marginBottomSmall) {
    margin-bottom: 25px;
}

:local(.marginBottom) {
    margin-bottom: 50px;
}

ol,
ul {
    padding-left: 20px;
}

:local(.subtitle) {
    display: block;
    margin-top: -4px;
}

:local(.fileList) {
    list-style: none;
    padding: 0;
    margin: 0;
    :local(.fileListItem) {
        padding: 10px 0;
        border-top: 1px solid #f2f1f0;
        display: flex;
        :local(.fileListItemTitle) {
            flex: 1;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        :local(.fileListInfoPrimary) {
            flex: 1;
            text-decoration: underline;
            cursor: pointer;
            background: none;
            border: none;
            font-family: inherit;
            font-size: inherit;
            text-align: left;
            &:hover {
                text-decoration: none;
            }
        }
        :local(.fileListInfoSecondary) {
            margin-left: 10px;
            width: 115px;
            text-align: right;
        }
    }
}

:local(.buttonRow) {
    display: flex;
    justify-content: space-between;
}

:local(.inlineList) {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    list-style: none;
    padding: 0;
}
