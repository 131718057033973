@import "../../../style/abstracts/_all.scss";

:local(.inputGroup) {
    text-align: left;
    margin: auto;
    @media (min-width: $screen-sm) {
        max-width: 620px;
        display: flex;
    }
    :local(.inputField) {
        @media (min-width: $screen-sm) {
            flex: 1;
        }
    }
    :local(.button) {
        margin-top: 21px;
        @media (min-width: $screen-sm) {
            margin-left: 12px;
        }
    }
}
