@import "./style/abstracts/_all.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $default-font;
  background: $body-background;
  color: $color-default-text;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: underline;
  color: $color-primary;
  &:hover {
    text-decoration: none;
  }
}
