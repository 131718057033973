:local(.attachmentListItem) {
    display: flex;
    align-items: center;
    padding: 10px 0;
    &:not(:first-child) {
        border-top: 1px solid #f2f1f0;
    }
    :local(.attachmentListItemText) {
        margin-left: 10px;
        :local(.attachmentListItemErrorMessage) {
            color: #c1272c;
            font-style: italic;
        }
    }
}
