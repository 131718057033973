@import "../../style/abstracts/_all.scss";

:local(.rightContainer) {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    position: relative;
    right: -20px;
    :local(.logOutButtonContainer) {
        button {
            img {
                display: inline-block;
                width: 21px;
                height: 21px;
                vertical-align: text-bottom;
            }
            span {
                display: none;
            }
            @media only screen and (min-width: $screen-sm) {
                img {
                    display: none;
                }
                span {
                    display: inline-block;
                }
            }
        }
    }
}
