@import "../../style/abstracts/_all.scss";

.mainContainer {
    display: flex;
    flex-wrap: wrap;
    .toggleNavigationButtonContainer {
        width: 100%;
        margin-bottom: 12px;
        @media screen and (min-width: $screen-md) {
            display: none;
        }
    }
    .navigationContainer {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        margin-bottom: 12px;
        border: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
        transition: max-height 0.3s ease-in-out 0s;
        .headerSection {
            padding: 24px;
            background-color: #fff;
        }
        &.expanded {
            max-height: 530px;
            @media screen and (min-width: $screen-md) {
                max-height: none;
            }
        }
        @media screen and (min-width: $screen-md) {
            top: 20px;
            width: 340px;
            height: 100vh;
            max-height: none;
            overflow: hidden scroll;
            position: sticky;
            margin-bottom: 0;
        }
    }

    .contentContainer {
        margin: 0 auto;
        width: 100%;
        @media print {
            height: auto;
            overflow: auto;
        }
        @media screen and (min-width: $screen-md) {
            margin: 0 0 0 24px;
            width: calc(100% - 364px);
        }
        .contentContainerHeader {
            margin-bottom: 30px;
        }
    }
}

.hidden {
    visibility: hidden;
    height: 0;
    width: 0;
}
